import * as React from 'react';
import './Settings.scss';
import { Toggle } from 'office-ui-fabric-react/lib-commonjs/Toggle';
import { ISettingsStrings } from './ISettingsStrings';
// import { Dropdown, IDropdownOption } from "office-ui-fabric-react";
// -- Fabric UI

// const options: IDropdownOption[] = [
//   { key: 'en', text: 'English' },
//   { key: 'de', text: 'German' },
// ];
interface IProps {
  tooltipStatusChange?: any;
  darkMode?: any;
  tooltipsStatus?: any;
  strings?: ISettingsStrings;
}
export class Settings extends React.PureComponent<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <div>
        <div className="settings__wrapper">
          <div className="settings__switch">
            <Toggle
              defaultChecked={this.props.tooltipsStatus}
              onChange={this.props.tooltipStatusChange}
            />
            <div className="bolded">
              {(this.props.strings &&
                this.props.strings.allwaysShowTooltipsToggleLabel) ||
                'Always show tooltips'}
            </div>
          </div>
          <div className="settings__switch">
            <Toggle
              defaultChecked={this.darkModeStatusFromLocalStorage()}
              onChange={(
                _event: React.MouseEvent<HTMLElement>,
                _checked?: boolean
              ) => {
                localStorage.setItem('darkMode', `${_checked}`);
                this.props.darkMode(_checked);
              }}
            />
            <div className="bolded">
              {(this.props.strings && this.props.strings.darkModeToggleLabel) ||
                'Dark mode'}
            </div>
          </div>

          {/* <Dropdown
              label="Select Language"
              defaultSelectedKey="en"
              options={options}
            /> */}
        </div>
      </div>
    );
  }

  darkModeStatusFromLocalStorage = () => {
    if (localStorage.getItem('darkMode') === null) {
      return false;
    } else if (localStorage.getItem('darkMode') === 'true') {
      return true;
    } else if (localStorage.getItem('darkMode') === 'false') {
      return false;
    }
  };
}
