import * as React from "react";
// -- Fabric UI

export class Help extends React.PureComponent<{}> {
  public render(): JSX.Element {
    return (
      <div className="slideLeftIn400">
        
      </div>
    );
  }
}