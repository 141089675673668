import {
  AccountInfo,
  Configuration,
  PublicClientApplication,
} from '@azure/msal-browser';
import { isIEBrowser } from '../utilities/Utils';

export interface IAuthProviderConfiguration {
  appId: string;
  redirectUri: string;
  scopes: Array<string>;
  tenantId?: string;
  tokenRenewalFailed?: number;
}

export class AuthProvider {
  userAgentApplication: PublicClientApplication;
  authenticationParameters: Configuration;
  configuration: IAuthProviderConfiguration;
  accountObj: Promise<AccountInfo | null>;

  constructor(configuration: IAuthProviderConfiguration) {
    this.configuration = configuration;

    this.authenticationParameters = {
      auth: {
        clientId: this.configuration.appId,
        redirectUri: this.configuration.redirectUri,
        authority: this.configuration.tenantId
          ? 'https://login.microsoftonline.com/' + this.configuration.tenantId
          : 'https://login.microsoftonline.com/common',
      },
      cache: {
        cacheLocation: isIEBrowser() ? 'localStorage' : 'sessionStorage', // Use localStorage for IE, as sessionStorage does not work for localhost.
        storeAuthStateInCookie: isIEBrowser(),
      },
      system: {
        tokenRenewalOffsetSeconds: this.configuration.tokenRenewalFailed
          ? this.configuration.tokenRenewalFailed
          : 30,
      },
    };

    this.userAgentApplication = new PublicClientApplication(
      this.authenticationParameters
    );

    const tokenPromise = this.userAgentApplication.handleRedirectPromise();
    this.accountObj = new Promise<AccountInfo | null>((resolve, reject) => {
      tokenPromise
        .then((tokenResponse) => {
          if (tokenResponse !== null) {
            resolve(tokenResponse.account);
          } else {
            const currentAccounts = this.userAgentApplication.getAllAccounts();
            if (!currentAccounts || currentAccounts.length === 0) {
              // No user signed in
              reject("There isn't any account detected.");
            } else if (currentAccounts.length > 1) {
              // More than one user signed in
              reject('Multiple accounts detected.');
            } else {
              resolve(currentAccounts[0]);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  login() {
    this.userAgentApplication.loginRedirect(this.configuration);
  }

  async getAccessToken(configuration?: IAuthProviderConfiguration) {
    const accountInfo = await this.accountObj;
    if (!accountInfo) {
      this.userAgentApplication.loginRedirect(this.configuration);
      return;
    }

    return await this.userAgentApplication
      .acquireTokenSilent({
        account: accountInfo,
        scopes: configuration
          ? configuration.scopes
          : this.configuration.scopes,
        authority: (
          configuration ? configuration.tenantId : this.configuration.tenantId
        )
          ? 'https://login.microsoftonline.com/' +
            (configuration
              ? configuration.tenantId
              : this.configuration.tenantId)
          : 'https://login.microsoftonline.com/common',
      })
      .catch(async () => {
        // fallback to interaction when silent call fails
        if (!accountInfo) {
          this.userAgentApplication.loginRedirect(this.configuration);
          return;
        }

        return await this.userAgentApplication.acquireTokenRedirect({
          account: accountInfo,
          scopes: configuration
            ? configuration.scopes
            : this.configuration.scopes,
          authority: (
            configuration ? configuration.tenantId : this.configuration.tenantId
          )
            ? 'https://login.microsoftonline.com/' +
              (configuration
                ? configuration.tenantId
                : this.configuration.tenantId)
            : 'https://login.microsoftonline.com/common',
        });
      });
  }

  logout() {
    this.userAgentApplication.logout();
  }
}
