import React from 'react';

interface iconProps {
  height?: string;
  width?: string;
  id?: string;
}

export const IntermediaryIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : '29'}
      height={props.height ? props.height : '30'}
      id={props.id}
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9983 15.4736C16.7969 13.4797 12.4315 13.4789 9.22698 15.4743C8.86442 15.7007 8.63916 16.1141 8.63916 16.5536V16.7738C8.81967 16.7269 9.00481 16.6938 9.19535 16.6753C9.00558 16.6938 8.81967 16.7269 8.63916 16.7738V21.5676C8.63916 21.8031 8.83047 21.9933 9.06576 21.9933H13.01H20.1603C20.3964 21.9933 20.5869 21.8031 20.5869 21.5676V16.5536C20.5861 16.1133 20.3609 15.6999 19.9983 15.4736Z"
        fill="#0095E1"
      />
      <path
        d="M19.9813 15.4697C18.1801 14.3481 16.01 13.8569 13.8863 13.9978C15.5372 14.1071 17.1602 14.5975 18.5604 15.4697C18.9229 15.6961 19.149 16.1095 19.149 16.5498V21.5637C19.149 21.7993 18.9584 21.9894 18.7224 21.9894H20.1433C20.3794 21.9894 20.5699 21.7993 20.5699 21.5637V16.5498C20.5699 16.1095 20.3439 15.6961 19.9813 15.4697Z"
        fill="#0077BF"
      />
      <path
        d="M14.613 13.0525C16.358 13.0525 17.7782 11.636 17.7782 9.89383C17.7782 8.1517 16.358 6.73521 14.613 6.73521C12.8681 6.73521 11.4479 8.15247 11.4479 9.89383C11.4479 11.636 12.8673 13.0525 14.613 13.0525Z"
        fill="#008CE1"
      />
      <path
        d="M17.782 9.8946C17.782 8.33184 16.6388 7.03083 15.1422 6.77986C15.9807 7.55662 16.5061 8.66595 16.5061 9.8946C16.5061 11.124 15.9807 12.2326 15.1422 13.0093C16.638 12.7584 17.782 11.4574 17.782 9.8946Z"
        fill="#0077BF"
      />
      <path
        d="M14.5266 26.4283C8.39151 26.4283 3.40042 21.4475 3.40042 15.325C3.40042 15.2103 3.40273 15.0956 3.40659 14.9817H5.69L2.845 12.1425L0 14.9817H2.18158C2.17849 15.0956 2.17618 15.2103 2.17618 15.325C2.17618 22.1211 7.71652 27.65 14.5266 27.65C19.8618 27.65 24.4178 24.2558 26.1411 19.5175L25.1738 18.5521C23.7844 23.1057 19.537 26.4283 14.5266 26.4283Z"
        fill="#00B8EE"
      />
      <path
        d="M26.877 15.2981C26.8624 8.5143 21.3274 3 14.5266 3C9.24701 3 4.73112 6.32336 2.96765 10.9839L3.92884 11.9431C5.366 7.46964 9.57332 4.22172 14.5266 4.22172C20.6525 4.22172 25.6381 9.1879 25.6528 15.2981H23.31L26.155 18.1372L29 15.2981H26.877Z"
        fill="#00B8EE"
      />
    </svg>
  );
};
