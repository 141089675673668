import * as React from 'react';
import './Notification.scss';
import { INotificationStrings } from './INotificationStrings';
// -- Fabric UI

export interface INotificationProps {
  strings?: INotificationStrings;
  messages?: () => any;
  messagesCount?: number;
}

export class Notification extends React.PureComponent<INotificationProps> {
  public render(): JSX.Element {
    return (
      <div>
        {this.props.messagesCount ? (
          this.props.messages && this.props.messages()
        ) : (
          <div className="notification__wrapper">
            <div className="empty">
              <span className="icon"></span>
              <div className="bolded">
                {(this.props.strings &&
                  this.props.strings.noNotificationsTitle) ||
                  'Nothing new right now'}
              </div>
              <div>
                {(this.props.strings &&
                  this.props.strings.noNotificationsSubtitle) ||
                  'Check again later for updates'}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
