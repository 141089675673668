import React from 'react';

interface iconProps {
  height?: string;
  width?: string;
}

export const ClientIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : '30'}
      height={props.height ? props.height : '35'}
      viewBox="0 0 30 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.7922 17.9524C22.2181 13.8547 13.2515 13.8538 6.67012 17.9533C5.92579 18.4178 5.4624 19.2675 5.4624 20.171V20.6228C6.0544 20.4689 6.67559 20.386 7.31685 20.386C11.3578 20.386 14.6343 23.6576 14.6343 27.6923C14.6343 28.2698 14.5668 28.8317 14.44 29.3709H29.1242C29.6086 29.3709 30.0008 28.9793 30.0008 28.4957V20.171C30.0008 19.2675 29.5374 18.4187 28.7922 17.9524Z"
        fill="#7B83EB"
      />
      <path
        d="M5.46301 28.4979V20.625C2.31966 21.4447 0 24.2991 0 27.6945C0 31.7293 3.27653 35.0009 7.31746 35.0009C10.7801 35.0009 13.6808 32.5991 14.4406 29.3731H6.33961C5.85524 29.3731 5.46301 28.9815 5.46301 28.4979Z"
        fill="#7B83EB"
      />
      <path
        d="M7.31685 20.3887C6.67651 20.3887 6.0544 20.4716 5.4624 20.6255V28.4983C5.4624 28.982 5.85464 29.3736 6.339 29.3736H14.44C14.5668 28.8344 14.6343 28.2734 14.6343 27.6959C14.6343 23.6602 11.3578 20.3887 7.31685 20.3887Z"
        fill="#235DC1"
      />
      <g opacity="0.64">
        <g opacity="0.64">
          <g opacity="0.64">
            <path
              opacity="0.64"
              d="M28.759 17.9479C25.0601 15.6427 20.6041 14.6345 16.2412 14.9232C19.6318 15.1482 22.9648 16.1564 25.84 17.9479C26.5853 18.4133 27.0486 19.2631 27.0486 20.1675V28.4921C27.0486 28.9758 26.6564 29.3674 26.1721 29.3674H29.091C29.5754 29.3674 29.9676 28.9758 29.9676 28.4921V20.1675C29.9667 19.2631 29.5042 18.4133 28.759 17.9479Z"
              fill="#235DC1"
            />
          </g>
        </g>
      </g>
      <path
        d="M17.7326 12.9806C21.3165 12.9806 24.2327 10.0688 24.2327 6.49028C24.2327 2.91179 21.3165 0 17.7326 0C14.1486 0 11.2324 2.91179 11.2324 6.49028C11.2324 10.0688 14.1486 12.9806 17.7326 12.9806Z"
        fill="#7B83EB"
      />
      <g opacity="0.64">
        <g opacity="0.64">
          <g opacity="0.64">
            <path
              opacity="0.64"
              d="M24.2409 6.48996C24.2409 3.27852 21.892 0.60444 18.8198 0.0898438C20.5411 1.68646 21.6202 3.96434 21.6202 6.48996C21.6202 9.01558 20.5411 11.2935 18.8198 12.8901C21.892 12.3755 24.2409 9.7014 24.2409 6.48996Z"
              fill="#235DC1"
            />
          </g>
        </g>
      </g>
      <path
        d="M10.6715 26.6903L8.49413 26.3578L7.51811 24.2821C7.44513 24.1273 7.19064 24.1273 7.11766 24.2821L6.14164 26.3578L3.96428 26.6903C3.7855 26.7176 3.71435 26.9353 3.83931 27.0628L5.42102 28.6822L5.04703 30.971C5.01693 31.1531 5.21213 31.2888 5.37268 31.1996L7.31743 30.1267L9.26218 31.2005C9.42181 31.2889 9.61793 31.155 9.58783 30.9719L9.21384 28.6831L10.7955 27.0637C10.9214 26.9353 10.8494 26.7185 10.6715 26.6903Z"
        fill="white"
      />
    </svg>
  );
};
