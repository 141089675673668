import jsonp from "jsonp";

export const searchAutosuggestions = async (
  query: string,
  map: Microsoft.Maps.Map
): Promise<Microsoft.Maps.ISuggestionResult[]> => {
  const results = await new Promise<Microsoft.Maps.ISuggestionResult[]>(
    (resolve, reject) => {
      let url = "";
      url += "https://dev.virtualearth.net/REST/v1/Autosuggest";
      url += `?query=${encodeURIComponent(query)}`;
      url += `&maxResults=10`;
      url += `&key=${process.env.REACT_APP_BING_MAPS_API_KEY}`;
      const latNw = (map.getBounds() as any).bounds[2];
      const longNw = (map.getBounds() as any).bounds[3];
      const latSe = (map.getBounds() as any).bounds[0];
      const longSe = (map.getBounds() as any).bounds[1];
      url += `&userMapView=${latNw},${longNw},${latSe},${longSe}`;

      jsonp(url, { param: "jsonp" }, (err, data) => {
        if (err) reject(err);
        if (!data) return resolve([]);
        if (data.resourceSets.length === 0) return resolve([]);
        const res = data.resourceSets[0].resources[0].value;
        resolve(res);
      });
    }
  );

  return results;
};

export const searchLocations = async (
  map: Microsoft.Maps.Map,
  options: {
    adminDistrict?: string;
    locality?: string;
    postalCode?: string;
    addressLine?: string;
    query?: string;
  }
): Promise<any> => {
  const results = await new Promise<any>((resolve, reject) => {
    let url = "";
    url += "https://dev.virtualearth.net/REST/v1/Locations/";
    url += `?key=${process.env.REACT_APP_BING_MAPS_API_KEY}`;
    url += "&maxResults=10";
    const latNw = (map.getBounds() as any).bounds[2];
    const longNw = (map.getBounds() as any).bounds[3];
    const latSe = (map.getBounds() as any).bounds[0];
    const longSe = (map.getBounds() as any).bounds[1];
    url += `&userMapView=${latNw},${longNw},${latSe},${longSe}`;
  if (!options.query) {
      if (options.adminDistrict) {
        url += `&adminDistrict=${options.adminDistrict}`;
      }
      if (options.locality) {
        url += `&locality=${options.locality}`;
      }
      if (options.postalCode) {
        url += `&postalCode=${options.postalCode}`;
      }
      if (options.addressLine) {
        url += `&addressLine=${options.addressLine}`;
      }
    } else {
      url += `&query=${options.query}`
    }
    jsonp(url, { param: "jsonp" }, (err, data) => {
      if (err) reject(err);
      if (!data) return resolve([]);
      if (data.resourceSets.length === 0) return resolve([]);
      const res = data.resourceSets[0].resources as any[];
      resolve(res);
    });
  });

  return results;
};

export const searchLocationByPoint = async (lat: number, long: number) => {
  const results = await new Promise<any>((resolve, reject) => {
    let url = "";
    url += "https://dev.virtualearth.net/REST/v1/Locations/";
    url += `${lat},${long}`;
    url += `?key=${process.env.REACT_APP_BING_MAPS_API_KEY}`;
    url += `&include=ciso2&includeNeighborhood=1`;

    jsonp(url, { param: "jsonp" }, (err, data) => {
      if (err) reject(err);
      if (!data) return resolve([]);
      if (data.resourceSets.length === 0) return resolve([]);
      const res = data.resourceSets[0].resources as any[];
      resolve(res);
    });
  });

  return results;
};
