import * as React from 'react';
import './AppLauncher.scss';
// -- Fabric UI
import { IconButton, Panel, PanelType } from 'office-ui-fabric-react';

interface IProps {
  closeAppLuncher: any;
  appLuncherIsOpen: boolean;
  gosNavigation?: Function;
}

export class AppLauncher extends React.PureComponent<IProps, {}> {
  public render(): JSX.Element {
    return (
      <Panel
        className="remove-shadow"
        isBlocking={false}
        type={PanelType.customNear}
        isOpen={this.props.appLuncherIsOpen}
        isLightDismiss={true}
        onDismiss={() => this.props.closeAppLuncher()}
        isHiddenOnDismiss={false}
        hasCloseButton={false}
        customWidth="320px"
      >
        <IconButton
          onClick={() => this.props.closeAppLuncher()}
          styles={{
            root: {
              height: '44px',
              width: '44px',
              margin: '2px',
              color: '#2780e3',
              fontSize: '24px',
            },
            rootHovered: {
              color: '#2780e3',
            },
          }}
          iconProps={{ iconName: 'WaffleOffice365' }}
        />

        {this.props.gosNavigation ? (
          <div onClick={() => this.props.closeAppLuncher()}>
            {this.props.gosNavigation()}
          </div>
        ) : (
          ''
        )}
      </Panel>
    );
  }
}
