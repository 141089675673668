import * as React from "react";

export interface IAppContext {
  setNotificationMessage: () => void;
  removeNotification: () => void;
  filterDuplicates: (errors: any) => void;
  clearAllNotifications: () => void;
  clearFormNotifications: () => void;
  notifications: [
    {
      errors: any;
      key: string;
      message: JSX.Element;
      messageBarType: number;
      fieldName?: string;
      handleNotificationClick?: Function;
      notify: any;
    }
  ];
  tooltipsStatus: boolean;
}

export const AppContext = React.createContext<IAppContext | any>({});
