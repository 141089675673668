import React, { createContext, useRef, ReactNode } from "react";

export const RefContext = createContext(
  {} as { setRef: (ref: any) => void; refs: { [key: string]: any } }
);

export const RefProvider = ({ children }: { children: ReactNode }) => {
  const refs = useRef<{ [key: string]: any }>({});

  const setRef = (key: string) => (ref: any) => {
    refs.current[key] = ref;
  };

  return (
    <RefContext.Provider value={{ refs: refs.current, setRef }}>
      {children}
    </RefContext.Provider>
  );
};
