export const accept = /[-\d,]+/g;

export const parse = (string: string) => {
  return (String(string).match(accept) || []).join("");
};

export const format = ({
  value,
  maxDecimals = 2,
  isNegativeAllowed,
  max,
}: {
  value;
  maxDecimals?: number;
  isNegativeAllowed?: boolean;
  max?: number;
}) => {
  let parsed = parse(value);
  if (parsed === "-") {
    if (!isNegativeAllowed) {
      return "";
    }
    return "-";
  }
  //
  else if (parsed.indexOf("-") !== -1) {
    if (!isNegativeAllowed) {
      parsed = "0";
    }
  }
  const [head, tail] = parsed.split(",");

  const scaledTail = tail != null ? tail.slice(0, maxDecimals) : "";

  let number = Number.parseFloat(`${head}.${scaledTail}`);

  if (Number.isNaN(number)) {
    return "";
  }

  if (max !== undefined && number > max) {
    number = max;
  }

  const formatted = number.toLocaleString("de-DE", {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDecimals,
  });

  if (parsed.includes(",")) {
    const [formattedHead] = formatted.split(",");
    return `${formattedHead.slice(0, 16)},${scaledTail}`;
  }

  return formatted.slice(0, 16);
};
