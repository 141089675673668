import React from 'react';

interface iconProps {
  height?: string;
  width?: string;
}

export const ClaimIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : '26'}
      height={props.height ? props.height : '26'}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3871 7.87939H4.93945C4.22631 7.87939 3.64813 8.46624 3.64813 9.19006V22.409H21.7314C22.2542 22.409 22.6784 21.9789 22.6784 21.4478V9.19047C22.6788 8.46665 22.1003 7.87939 21.3871 7.87939Z"
        fill="#005AA1"
      />
      <path
        d="M19.6239 7.87939H4.93945C4.22631 7.87939 3.64813 8.46624 3.64813 9.19006V22.409H19.6235V7.87939H19.6239Z"
        fill="#0667C4"
      />
      <path
        d="M4.92364 22.3931C4.20889 22.3931 3.62909 21.805 3.62909 21.0791V15.6023C1.53495 16.2031 0 18.1572 0 20.4755C0 23.2719 2.23353 25.5389 4.98869 25.5389C7.07515 25.5389 8.86222 24.2385 9.60687 22.3931H6.67798H4.92364Z"
        fill="url(#paint0_linear)"
      />
      <path
        opacity="0.69"
        d="M4.98905 15.4119C4.51754 15.4119 4.06137 15.4783 3.62946 15.6021V21.079C3.62946 21.8044 4.20885 22.3929 4.924 22.3929H6.67875H9.60764C9.84642 21.8016 9.97814 21.154 9.97814 20.4753C9.97774 17.6789 7.7442 15.4119 4.98905 15.4119Z"
        fill="url(#paint1_linear)"
      />
      <path
        opacity="0.9"
        d="M5.36768 16.8022C4.08566 17.5519 4.2396 19.6643 4.2396 19.6643C4.2396 19.6643 3.67556 19.4736 3.67556 18.6149C3.00284 19.011 2.54749 19.7722 2.54749 20.6182C2.54749 21.8825 3.55758 22.9078 4.80324 22.9078C6.04889 22.9078 7.05899 21.8825 7.05899 20.6182C7.0598 18.758 5.36768 18.3762 5.36768 16.8022Z"
        fill="white"
      />
      <path
        d="M25.4436 9.46356C25.7919 9.46356 25.9333 9.00918 25.6489 8.80577L13.6036 0.196655C13.2614 -0.0678563 12.7867 -0.0653958 12.4473 0.203216L0.674756 8.80782C0.393948 9.01328 0.536978 9.46356 0.883241 9.46356H25.4436Z"
        fill="#0667C4"
      />
      <path
        opacity="0.88"
        d="M12.8242 13.09H9.84445C9.80081 13.09 9.76526 13.0539 9.76526 13.0096V9.98516C9.76526 9.94087 9.80081 9.90479 9.84445 9.90479H12.8242C12.8679 9.90479 12.9034 9.94087 12.9034 9.98516V13.0096C12.9038 13.0539 12.8683 13.09 12.8242 13.09Z"
        fill="white"
      />
      <path
        d="M16.482 13.09H13.5022C13.4586 13.09 13.423 13.0539 13.423 13.0096V9.98516C13.423 9.94087 13.4586 9.90479 13.5022 9.90479H16.482C16.5257 9.90479 16.5612 9.94087 16.5612 9.98516V13.0096C16.5616 13.0539 16.5261 13.09 16.482 13.09Z"
        fill="#ADC5F2"
      />
      <path
        opacity="0.88"
        d="M16.482 16.7494H13.5022C13.4586 16.7494 13.423 16.7133 13.423 16.669V13.6446C13.423 13.6003 13.4586 13.5642 13.5022 13.5642H16.482C16.5257 13.5642 16.5612 13.6003 16.5612 13.6446V16.669C16.5616 16.7133 16.5261 16.7494 16.482 16.7494Z"
        fill="white"
      />
      <path
        d="M12.8242 16.7494H9.84445C9.80081 16.7494 9.76526 16.7133 9.76526 16.669V13.6446C9.76526 13.6003 9.80081 13.5642 9.84445 13.5642H12.8242C12.8679 13.5642 12.9034 13.6003 12.9034 13.6446V16.669C12.9038 16.7133 12.8683 16.7494 12.8242 16.7494Z"
        fill="#ADC5F2"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="-5.4142"
          y1="10.3128"
          x2="9.05181"
          y2="26.8866"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2712" stopColor="#FF406E" />
          <stop offset="0.967" stopColor="#FF8D52" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="-3.14865"
          y1="8.33525"
          x2="11.3173"
          y2="24.909"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2712" stopColor="#FF406E" />
          <stop offset="0.967" stopColor="#FF8D52" />
        </linearGradient>
      </defs>
    </svg>
  );
};
