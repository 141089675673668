import React from 'react';

interface iconProps {
  height?: string;
  width?: string;
}

export const TmsIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : '25'}
      height={props.height ? props.height : '20'}
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.3975 14.675V4.19314C24.3975 3.48495 23.8258 2.91064 23.1207 2.91064H21.3901V15.0552C21.3901 15.7378 21.8547 16.3321 22.5153 16.4938L23.148 16.6487C23.8857 16.2997 24.3975 15.5483 24.3975 14.675Z"
        fill="#235DC1"
      />
      <path
        d="M22.5153 16.4941C21.8547 16.3324 21.3901 15.7382 21.3901 15.0556V2.91062V1.28249C21.3901 0.574301 20.8183 0 20.1132 0H4.84068C4.13561 0 3.56384 0.574301 3.56384 1.28249V15.5757C3.56384 16.2839 4.13561 16.8582 4.84068 16.8582H6.57133H22.2239C22.5546 16.8582 22.8669 16.7818 23.1477 16.6491L22.5153 16.4941Z"
        fill="#5D8DE4"
      />
      <path
        opacity="0.88"
        d="M17.9917 11.7064H9.2875C9.24706 11.7064 9.21411 11.6733 9.21411 11.6327V10.4773C9.21411 10.4367 9.24706 10.4036 9.2875 10.4036H17.9917C18.0321 10.4036 18.0651 10.4367 18.0651 10.4773V11.6327C18.0654 11.6733 18.0325 11.7064 17.9917 11.7064Z"
        fill="white"
      />
      <path
        d="M7.22852 11.8194C7.67831 11.8194 8.04293 11.4531 8.04293 11.0014C8.04293 10.5496 7.67831 10.1833 7.22852 10.1833C6.77874 10.1833 6.41412 10.5496 6.41412 11.0014C6.41412 11.4531 6.77874 11.8194 7.22852 11.8194Z"
        fill="#ADC5F2"
      />
      <path
        d="M17.9917 8.48469H9.2875C9.24706 8.48469 9.21411 8.45159 9.21411 8.41097V7.2556C9.21411 7.21498 9.24706 7.18188 9.2875 7.18188H17.9917C18.0321 7.18188 18.0651 7.21498 18.0651 7.2556V8.41097C18.0654 8.45159 18.0325 8.48469 17.9917 8.48469Z"
        fill="#ADC5F2"
      />
      <path
        opacity="0.88"
        d="M7.22852 8.59794C7.67831 8.59794 8.04293 8.2317 8.04293 7.77993C8.04293 7.32815 7.67831 6.96191 7.22852 6.96191C6.77874 6.96191 6.41412 7.32815 6.41412 7.77993C6.41412 8.2317 6.77874 8.59794 7.22852 8.59794Z"
        fill="white"
      />
      <path
        opacity="0.88"
        d="M17.9917 5.26301H9.2875C9.24706 5.26301 9.21411 5.22991 9.21411 5.18929V4.03392C9.21411 3.9933 9.24706 3.96021 9.2875 3.96021H17.9917C18.0321 3.96021 18.0651 3.9933 18.0651 4.03392V5.18929C18.0654 5.22991 18.0325 5.26301 17.9917 5.26301Z"
        fill="white"
      />
      <path
        d="M7.22852 5.37626C7.67831 5.37626 8.04293 5.01003 8.04293 4.55825C8.04293 4.10647 7.67831 3.74023 7.22852 3.74023C6.77874 3.74023 6.41412 4.10647 6.41412 4.55825C6.41412 5.01003 6.77874 5.37626 7.22852 5.37626Z"
        fill="#ADC5F2"
      />
      <path
        d="M4.85645 16.9102C4.15139 16.9102 3.57962 16.3359 3.57962 15.6277V10.2815C1.51385 10.8678 0 12.7754 0 15.038C0 17.7677 2.20319 19.9807 4.92085 19.9807C6.97876 19.9807 8.74161 18.7113 9.47588 16.9098H6.5871H4.85645V16.9102Z"
        fill="#3177DB"
      />
      <path
        d="M4.92082 10.0955C4.45577 10.0955 4.00607 10.1601 3.57959 10.2813V15.6271C3.57959 16.3353 4.15136 16.9096 4.85642 16.9096H6.58707H9.47586C9.71138 16.3323 9.84131 15.7004 9.84131 15.0378C9.84168 12.3084 7.63849 10.0955 4.92082 10.0955Z"
        fill="#235DC1"
      />
      <path
        d="M6.87168 12.5942L4.01585 15.4642L2.5488 13.9888L1.67224 14.8726L3.13929 16.3481L4.07164 17.2861L4.94819 16.4022L7.80403 13.5326L6.87168 12.5942Z"
        fill="#C5FDF4"
      />
    </svg>
  );
};
