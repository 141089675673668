import React from 'react';

interface iconProps {
  height?: string;
  width?: string;
  className?: string;
}

export const PartnerIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : '31'}
      height={props.height ? props.height : '30'}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M29.028 14.825C23.7296 11.53 16.5023 11.529 11.1987 14.826C10.5989 15.1995 10.2251 15.8831 10.2251 16.6096V16.9729C10.5229 16.8951 10.831 16.8399 11.1453 16.8102C10.83 16.8409 10.5229 16.8951 10.2251 16.9729V24.8951C10.2251 25.284 10.5404 25.5992 10.9317 25.5992H17.4606H29.2951C29.6853 25.5992 30.0017 25.284 30.0017 24.8951V16.6096C30.0017 15.8821 29.6289 15.1995 29.028 14.825Z"
        fill="#0095E1"
      />
      <g opacity="0.15">
        <g opacity="0.15">
          <g opacity="0.15">
            <path
              opacity="0.15"
              d="M29.0002 14.8169C26.0187 12.9627 22.4272 12.1522 18.9116 12.3845C21.6446 12.5656 24.3302 13.3761 26.6483 14.8169C27.2491 15.1914 27.6219 15.8749 27.6219 16.6015V24.887C27.6219 25.2759 27.3066 25.591 26.9153 25.591H29.2672C29.6575 25.591 29.9738 25.2759 29.9738 24.887V16.6015C29.9738 15.8749 29.601 15.1914 29.0002 14.8169Z"
              fill="black"
            />
          </g>
        </g>
      </g>
      <path
        d="M20.1134 10.8224C23.0025 10.8224 25.3523 8.4811 25.3523 5.60259C25.3523 2.72409 23.0025 0.382812 20.1134 0.382812C17.2244 0.382812 14.8745 2.72409 14.8745 5.60259C14.8745 8.4811 17.2244 10.8224 20.1134 10.8224Z"
        fill="#008CE1"
      />
      <g opacity="0.15">
        <g opacity="0.15">
          <g opacity="0.15">
            <path
              opacity="0.15"
              d="M25.3597 5.60416C25.3597 3.02138 23.4669 0.870439 20.9907 0.457031C22.3782 1.74125 23.2481 3.57294 23.2481 5.60416C23.2481 7.63538 22.3782 9.46706 20.9907 10.7513C23.4669 10.3379 25.3597 8.18795 25.3597 5.60416Z"
              fill="black"
            />
          </g>
        </g>
      </g>
      <path
        d="M22.3729 17.1791C16.0679 13.2578 7.46962 13.2578 1.15849 17.1801C0.443679 17.6252 0 18.4377 0 19.3034V19.7363C0.355354 19.6442 0.720978 19.5787 1.09482 19.5418C0.720978 19.5777 0.354327 19.6431 0 19.7363V29.1618C0 29.6243 0.375894 29.9988 0.841141 29.9988H8.60963H22.6913C23.1555 29.9988 23.5314 29.6243 23.5314 29.1618V19.3024C23.5314 18.4377 23.0877 17.6252 22.3729 17.1791Z"
        fill="#00B8EE"
      />
      <path
        d="M11.7658 12.4217C15.2033 12.4217 17.9989 9.63526 17.9989 6.21134C17.9989 2.78641 15.2022 0 11.7658 0C8.32933 0 5.53271 2.78641 5.53271 6.21134C5.53271 9.63526 8.32933 12.4217 11.7658 12.4217Z"
        fill="#00B8EE"
      />
      <path
        d="M11.5346 14.239C11.2162 14.2462 10.4994 14.2789 10.4994 14.2789L9.24432 22.6627C9.21453 22.8592 9.25972 23.0608 9.36859 23.2132L11.016 25.5555C11.1279 25.7152 11.2953 25.8062 11.473 25.8062C11.6507 25.8062 11.817 25.7131 11.929 25.5535L13.5774 23.2081C13.6862 23.0567 13.7314 22.8561 13.7017 22.6617L12.4209 14.2482C12.4209 14.2493 11.6979 14.2349 11.5346 14.239Z"
        fill="white"
      />
    </svg>
  );
};
