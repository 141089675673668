import { mergeStyleSets } from "@uifabric/merge-styles";
import React from "react";

const classes = mergeStyleSets({
  errorMsg: {
    color: "rgb(164, 38, 44)",
    fontSize: 12,
    paddingTop: 5,
    height: 21,
  },
});

const ErrorMsg = ({ id, children }) => {
  return (
    <p id={id} className={classes.errorMsg}>
      {children}
    </p>
  );
};

export default ErrorMsg;
